
import Vue from "vue";
import CfpForm from "./components/CfpForm.vue";
import Footer from "./components/Footer.vue";

export default Vue.extend({
  name: "App",
  components: {
    CfpForm,
    Footer,
  },
  watch: {
    $route(to: any, from: any) {
      this.showMenu = false;
    },
  },
  methods: {
    toggleMenu(event: any) {
      this.showMenu = !this.showMenu;
    },
    clickListener(event: any) {
      this.showMenu = false;
    },
    getWindowWidth() {
      this.largeScreen = window.innerWidth > 700;
    },
  },
  mounted() {
    this.$nextTick(function () {
      window.addEventListener("resize", this.getWindowWidth);
      this.getWindowWidth();
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.getWindowWidth);
  },
  data: () => ({
    showMenu: false,
    largeScreen: window.innerWidth > 700,
    menu: [
      {
        link: "/",
        title: "Hem",
      },
      // {
      //   link: "/anmalan",
      //   title: "Anmälan",
      // },
      {
        link: "/program",
        title: "Program",
      },
      {
        link: "/kidz",
        title: "Kidz",
      },
      // {
      //   link: "/cfp",
      //   title: "Cfp",
      // },
      {
        link: "/sponsra",
        title: "Sponsra!",
      },
      {
        link: "/about",
        title: "Om Umedev",
      },
    ],
  }),
});
