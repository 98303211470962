import { render, staticRenderFns } from "./Sponsring.vue?vue&type=template&id=2dc2decb&scoped=true"
import script from "./Sponsring.vue?vue&type=script&lang=ts"
export * from "./Sponsring.vue?vue&type=script&lang=ts"
import style0 from "./Sponsring.vue?vue&type=style&index=0&id=2dc2decb&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2dc2decb",
  null
  
)

export default component.exports