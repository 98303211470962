
import Vue from "vue";
import { db } from "../db";

export default Vue.extend({
  name: "EditRegister",
  created() {
    this.fetchData();
  },
  data: () => ({
    deletedRegisterSuccess: false,
    noRegisterWithId: false,
    showRegister: false,
    multiLine: true,
    snackbar: false,
    snackbarText: "",
    anmalan: {},
  }),
  methods: {
    deleteRegister() {
      const badConnection = this.checkInternetConnection();
      if (badConnection) {
        return;
      }

      const confirmDelete = window.confirm(
        "Är du säker på att du att du vill avboka din plats? ",
      );

      if (confirmDelete) {
        const that = this;
        db.ref("register/" + this.$route.params.id)
          .remove()
          .then(() => {
            that.deletedRegisterSuccess = true;
            that.showRegister = false;
          })
          .catch((error) => {
            that.snackbarText =
              "Något gick fel. Var vänlig försök igen senare.";
            that.snackbar = true;
          });
      }
    },
    checkInternetConnection() {
      if (!navigator.onLine) {
        this.snackbarText =
          "Du verkar sakna internetuppkoppling. Anslut till internet och prova igen. ";
        this.snackbar = true;
        return true;
      }
    },
    fetchData() {
      const badConnection = this.checkInternetConnection();
      if (badConnection) {
        return;
      }
      const that = this;
      const registerDb = db.ref("register/" + this.$route.params.id);
      registerDb.once("value").then(
        (snapshot) => {
          if (snapshot.val() !== null) {
            that.anmalan = snapshot.val();
            that.showRegister = true;
          } else {
            that.noRegisterWithId = true;
          }
        },
        (error) => {
          that.snackbarText = "Något gick fel. Var vänlig försök igen senare.";
          that.snackbar = true;
        },
      );
    },
  },
});
