
import Vue from 'vue'
import { Booking } from 'src/model/Schedule'
export default Vue.extend({
  data() {
    return {
      windowHeight: window.innerHeight,
      open: false,
      contentClass: 'booking-content',
    }
  },
  props: {
    singleTrack: Boolean,
    timeSlot: String,
    booking: Object as () => Booking,
  },
  methods: {
  },
})
