
import Vue, { PropType } from "vue";

type Keynote = {
  title: string;
  description: string;
  url?: string;
  speaker: Speaker;
};

type Speaker = {
  name: string;
  title: string;
  imageUrl: string;
};

export default {
  name: "KeynoteSpeakerCard",
  props: {
    title: String,
    openingKeynote: {
      type: Object as PropType<Keynote>,
      required: true,
    },
    endingKeynote: {
      type: Object as PropType<Keynote>,
      required: true,
    },
  },
};
