
import Vue from "vue";
import HeaderWithPepper from "../components/HeaderWithPepper.vue";

export default Vue.extend({
  name: "About",
  components: {
    HeaderWithPepper,
  },
  data: () => ({
    //
  }),
});
