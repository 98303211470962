
import Vue from 'vue'
import { Schedule } from 'src/model/Schedule'
import ScheduleBooking from './ScheduleBooking.vue'
export default Vue.extend({
  components: {
    ScheduleBooking,
  },
  props: {
    schedule: Object as () => Schedule,
  },
})
