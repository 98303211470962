
import Vue from 'vue';
import { db } from '../db';

const registerDb = db.ref('kidz');
const registerDefault = {
  name: '',
  age: '',
  selectedWorkshop1: '',
  selectedWorkshop2: '',
  parentName: '',
  parentPhone: '',
  parentEmail: '',
  other: '',
};
export default Vue.extend({
  name: 'RegisterKidz',
  data: () => ({
    valid: true,
    multiLine: true,
    snackbar: false,
    snackbarText: '',
    workshops: [
      'Scratch',
      'Micro:bit',
      'Designworkshop',
      'CodeCombat',
      'Kodstuga med Kattis',
    ],
    register: JSON.parse(JSON.stringify(registerDefault)),
    nameRules: [(v: string) => !!v || 'Namn är obligatoriskt'],
    ageRules: [
      (v: string) => !!v || 'Ålder är obligatoriskt',
      (v: string) =>
        (v && parseInt(v) >= 10 && parseInt(v) < 16) ||
        'Ålder måste vara mellab 10 och 15',
    ],
    emailRules: [
      (v: string) => !!v || 'Email adress är obligatoriskt',
      (v: string) => /.+@.+\..+/.test(v) || 'Email måste vara i rätt format',
    ],
    workshopRules: [(v: string) => !!v || 'Val av workshop är obligatoriskt'],
  }),
  computed: {
    form(): Vue & { validate: () => boolean; reset: () => boolean } {
      return this.$refs.registerForm as Vue & {
        validate: () => boolean;
        reset: () => boolean;
      };
    },
  },
  methods: {
    checkWorkshop() {
      if (this.register.selectedWorkshop1 === this.register.selectedWorkshop2) {
        return 'Förstahandsval och andrahandsval kan inte vara samma';
      }
      return true;
    },

    submit() {
      if (!navigator.onLine) {
        this.snackbarText =
          'Du verkar sakna internetuppkoppling. Anslut till internet och prova igen. ';
        this.snackbar = true;
        return;
      }

      const that = this;

      const onComplete = (error: any) => {
        if (error) {
          that.snackbarText = 'Något gick fel. Var vänlig försök igen senare.';
        } else {
          that.reset();
          that.snackbarText = 'Tack för din anmälan!';
        }
        that.snackbar = true;
      };

      if (this.form.validate()) {
        try {
          registerDb.push(this.register, onComplete);
        } catch (e) {
          this.snackbarText = 'Oväntat fel. Var vänlig försök igen senare.';
          this.snackbar = true;
        }
      }
    },
    reset() {
      this.form.reset();
      this.register = JSON.parse(JSON.stringify(registerDefault));
    },
  },
});
