
import Vue from "vue";
import { db } from "../db";
const proposalsDb = db.ref("proposals-2022");
const proposalDefaults = {
  name: "",
  descriptionOfSpeaker: "",
  title: "",
  email: "",
  target: "",
  description: "",
  typeOfTalk: "",
  otherInfo: "",
  newSpeaker: "",
  equipment: {
    internet: false,
    projector: false,
    whiteboard: false,
    flipboard: false,
  },
  wantSomeoneTodiscussWith: false,
  approved: false,
};
export default Vue.extend({
  data: () => ({
    valid: true,
    multiLine: true,
    snackbar: false,
    snackbarText: "",
    proposal: JSON.parse(JSON.stringify(proposalDefaults)),
    nameRules: [
      (v: string) => !!v || "Namn är obligatoriskt",
      (v: string) =>
        (v && v.length <= 30) || "Namn måste vara mindre än 30 bokstäver",
    ],
    emailRules: [
      (v: string) => !!v || "Email adress är obligatoriskt",
      (v: string) => /.+@.+\..+/.test(v) || "Email måste vara i rätt format",
    ],
    titleRules: [
      (v: string) => !!v || "Titel är obligatoriskt",
      (v: string) =>
        (v && v.length <= 100) || "Titeln måste vara mindre än 100 bokstäver",
    ],
    descriptionRules: [
      (v: string) => !!v || "Beskrivning är obligatoriskt",
      (v: string) =>
        (v && v.length <= 1000) ||
        "Beskrivningen måste vara mindre än 1000 bokstäver",
    ],
    typeOfTalkRules: [(v: string) => !!v || "Typ av pass är obligatoriskt"],
    descriptionOfSpeakerRules: [
      (v: string) => !!v || "Beskrivning av dig är obligatoriskt",
      (v: string) =>
        (v && v.length <= 1000) ||
        "Beskrivningen måste vara mindre än 1000 bokstäver",
    ],
  }),
  computed: {
    form(): Vue & { validate: () => boolean; reset: () => boolean } {
      return this.$refs.form as Vue & {
        validate: () => boolean;
        reset: () => boolean;
      };
    },
  }, // Use it like so: this.form.validate()
  methods: {
    submit() {
      if (!navigator.onLine) {
        this.snackbarText =
          "Du verkar sakna internetuppkoppling. Anslut till internet och prova igen. ";
        this.snackbar = true;
        return;
      }

      const that = this;

      const onComplete = (error: any) => {
        if (error) {
          that.snackbarText = "Något gick fel. Var vänlig försök igen senare.";
        } else {
          that.reset();
          that.snackbarText = "Tack för ditt bidrag!";
        }
        that.snackbar = true;
      };

      if (this.form.validate()) {
        try {
          console.log(this.form);
          //proposalsDb.push(this.proposal, onComplete);
        } catch (e) {
          this.snackbarText = "Oväntat fel. Var vänlig försök igen senare.";
          this.snackbar = true;
        }
      }
    },
    reset() {
      this.form.reset();
      this.proposal = JSON.parse(JSON.stringify(proposalDefaults));
    },
    resetValidation() {
      //    this.$refs.form.resetValidation();
    },
  },
});
