
import Vue from 'vue'
import HeaderWithPepper from '@/components/HeaderWithPepper.vue'
import KeynoteSpeakerCard from '@/components/KeynoteSpeakerCard.vue'

export default {
  name: 'Home',
  components: {
    HeaderWithPepper,
    KeynoteSpeakerCard,
  },
}
